import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { Res } from "../../utils/constants"
// import { GtmEventType, GtmAction, GtmEventCategory, pushToDataLayer } from "../../utils/handlers/gtmHandler"

import * as S from "./style"

/*
  const gtmData = {
    eventType: GtmEventType.BUTTON_CLICK,
    action: GtmAction.BANNERFLOW_DYNAMIC_BANNER_CLICK,
    label: "Bannerflow Dynamic Banner"
  }
*/

const BannerflowBanner = ({ bannerflowDesktopBanner, bannerflowMobileBanner }) => {
  const divRef = useRef()

  const isMobile = typeof window !== "undefined" && window.innerWidth <= Res._768

  useEffect(() => {
    // Create a script element
    const src = isMobile ? bannerflowMobileBanner : bannerflowDesktopBanner
    const script = document.createElement("script")
    script.src = `${src}&redirecturl=off`

    script.async = true

    // Specify the container where you want the banner to be injected
    const bannerContainer = divRef?.current
    if (bannerContainer) {
      bannerContainer.appendChild(script) // Append script to the div
    }

    // Cleanup function to remove the script when the component unmounts
    return () => {
      if (bannerContainer) {
        bannerContainer.removeChild(script) // Remove script on unmount
      }
    }
  }, [])

  return (
    <S.BannerContainer>
      <S.Inner ref={divRef} />
    </S.BannerContainer>
  )
}

BannerflowBanner.propTypes = {
  bannerflowDesktopBanner: PropTypes.string.isRequired,
  bannerflowMobileBanner: PropTypes.string.isRequired
}

export default BannerflowBanner

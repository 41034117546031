import styled from "styled-components"

const LAYOUT_CHANGE_BREAKPOINT = 860

export const BannerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  margin-left: 10px;
  z-index: 1;
  background-color: #fff;
  max-width: 250px;

  display: inline-flex;
  align-items: center;

  :before,
  :after {
    content: "";
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -7px;
    background-color: #fff;
    width: 15px;
    transform: skew(-10deg);
  }
  :after {
    right: -7px;
    left: auto;
  }

  @media (min-width: ${LAYOUT_CHANGE_BREAKPOINT}px) {
    //padding: 29px 16px;
  }
`

export const Inner = styled.div`
  position: relative;
  max-height: 100%;

  z-index: 1;

  display: flex;
  flex-direction: column;

  max-width: auto;

  margin: 0 auto;

  overflow: hidden;

  div {
    max-height: 100%;
    width: auto;
  }
  width: 100%;

  flex-direction: row;
  justify-content: space-around;
`
